var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          background: '#F2F2F2'
        }
      },
      expression: "{\n    ':hover': {\n      background: '#F2F2F2',\n    },\n  }"
    }],
    attrs: {
      "position": "relative",
      "width": "100%",
      "flex-direction": "column",
      "align-items": "center",
      "padding": ['4px', '8px'],
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.onClickGlossary(_vm.i);
      }
    }
  }, [_c('c-box', {
    attrs: {
      "position": "relative"
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['80px', '100px'],
      "height": ['80px', '100px'],
      "mb": "4px",
      "border-radius": "6px",
      "margin-bottom": "4px"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1)], 1), _c('c-box', {
    attrs: {
      "w": ['90px', '140px'],
      "h": ['18px', '20px'],
      "mb": "4px"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": ['50px', '60px'],
      "h": ['18px', '20px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }