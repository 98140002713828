var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "pos": "relative"
    }
  }, [!_vm.loaded && !_vm.error ? _c('c-box', {
    attrs: {
      "pos": "absolute",
      "top": "50%",
      "left": "50%",
      "transform": "translate(-50%, -50%)"
    }
  }, [_c('c-spinner', {
    attrs: {
      "thickness": "4px",
      "speed": "0.65s",
      "empty-color": "green.200",
      "color": "vue.500",
      "size": "lg"
    }
  })], 1) : _vm._e(), !_vm.error ? _c('c-image', _vm._g(_vm._b({
    on: {
      "load": _vm.onLoaded,
      "error": _vm.onError
    }
  }, 'c-image', _vm.$attrs, false), _vm.$listeners)) : _c('c-flex', _vm._g(_vm._b({
    attrs: {
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      "background-color": "#e5e5e5",
      "color": "#737373",
      "text-align": "center",
      "font-size": ['10px', '12px'],
      "font-weight": "500"
    }
  }, 'c-flex', _vm.$attrs, false), _vm.$listeners), [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  }, [_c('path', {
    attrs: {
      "fill": "currentColor",
      "d": "M30 3.414L28.586 2L2 28.586L3.414 30l2-2H26a2.003 2.003 0 0 0 2-2V5.414zM26 26H7.414l7.793-7.793l2.379 2.379a2 2 0 0 0 2.828 0L22 19l4 3.997zm0-5.832l-2.586-2.586a2 2 0 0 0-2.828 0L19 19.168l-2.377-2.377L26 7.414zM6 22v-3l5-4.997l1.373 1.374l1.416-1.416l-1.375-1.375a2 2 0 0 0-2.828 0L6 16.172V6h16V4H6a2.002 2.002 0 0 0-2 2v16z"
    }
  })]), _c('c-text', {
    attrs: {
      "mt": "4px"
    }
  }, [_vm._v(" NO IMAGE ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }