var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "position": "relative"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "p": "0",
      "position": "relative"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onOpenMenu.apply(null, arguments);
      }
    }
  }, [_c('c-box', {
    attrs: {
      "width": "30px",
      "height": "30px",
      "color": "#008C81"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-tabler-filter.svg'),
      "height": "100%",
      "width": "100%"
    }
  })], 1), _vm.selected && _vm.selected.length > 0 ? _c('c-flex', {
    attrs: {
      "pos": "absolute",
      "top": "0",
      "right": "0",
      "width": "16px",
      "height": "16px",
      "align-items": "center",
      "justify-content": "center",
      "border-radius": "100%",
      "font-size": "10px",
      "background": "#ef4444",
      "color": "#FFF"
    }
  }, [_vm._v(" " + _vm._s(_vm.selected.length) + " ")]) : _vm._e()], 1), _vm.isOpen ? _c('c-box', {
    attrs: {
      "position": "absolute",
      "top": "0",
      "right": "40px",
      "min-width": ['280px', '450px'],
      "max-width": ['300px', '500px'],
      "flex-direction": "column",
      "padding": "0",
      "background-color": "#FFF",
      "border": "1px solid #E2E8F0",
      "border-radius": "6px",
      "z-index": "10"
    }
  }, [_c('c-flex', {
    attrs: {
      "p": "10px 10px 10px 20px",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-size": ['14px', '16px'],
      "font-weight": ['500', '600']
    }
  }, [_vm._v(" Filter ")]), _c('c-button', {
    attrs: {
      "variant": "ghost",
      "variant-color": "danger",
      "font-size": ['14px', '16px']
    },
    on: {
      "click": _vm.onRemoveAllFilter
    }
  }, [_vm._v(" Hapus Semua ")])], 1), _vm.filters.length > 0 ? _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "align-items": "center",
      "max-height": "350px",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm.filters, function (item) {
    var _item$basicFoodIngred, _item$basicFoodIngred2;

    return _c('c-flex', {
      key: item === null || item === void 0 ? void 0 : item.foodGroup,
      attrs: {
        "width": "100%",
        "flex-direction": "column"
      }
    }, [_c('c-flex', {
      attrs: {
        "width": "100%",
        "align-items": "center",
        "justify-content": "space-between",
        "border-top-style": "solid",
        "border-top-width": "1px",
        "border-top-color": "#E2E8F0",
        "p": "10px 10px 10px 20px"
      }
    }, [_c('c-text', {
      attrs: {
        "flex-grow": "1",
        "color": "primary.400",
        "font-size": ['14px', '16px'],
        "font-weight": ['500', '600']
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.foodGroup) + " ")]), _c('c-flex', {
      attrs: {
        "flex-shrink": "0",
        "align-items": "center",
        "gap": "8px"
      }
    }, [_c('c-box', {
      attrs: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.onSelectFilter(item === null || item === void 0 ? void 0 : item.foodGroup);
        }
      }
    }, [_c('c-checkbox', {
      attrs: {
        "size": "lg",
        "variant-color": "primary",
        "pointer-events": 'none',
        "is-checked": _vm.selected_.includes(item === null || item === void 0 ? void 0 : item.foodGroup)
      }
    })], 1), (item === null || item === void 0 ? void 0 : (_item$basicFoodIngred = item.basicFoodIngredient) === null || _item$basicFoodIngred === void 0 ? void 0 : _item$basicFoodIngred.length) > 0 ? _c('c-button', {
      attrs: {
        "variant": "ghost",
        "size": "sm",
        "padding-inline": "6px"
      },
      on: {
        "click": function click($event) {
          return _vm.onToggleFoodGroup(item === null || item === void 0 ? void 0 : item.foodGroup);
        }
      }
    }, [_c('c-box', {
      attrs: {
        "transform": _vm.openFoodGroup.includes(item === null || item === void 0 ? void 0 : item.foodGroup) ? ['rotate(180deg)'] : ['rotate(0deg)'],
        "width": ['12px', '16px'],
        "height": ['12px', '16px']
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-arrow-down.svg'),
        "fill": "#888",
        "width": "100%",
        "height": "100%"
      }
    })], 1)], 1) : _vm._e()], 1)], 1), _c('transition-group', {
      attrs: {
        "name": "slide-down"
      }
    }, [(item === null || item === void 0 ? void 0 : (_item$basicFoodIngred2 = item.basicFoodIngredient) === null || _item$basicFoodIngred2 === void 0 ? void 0 : _item$basicFoodIngred2.length) > 0 && _vm.openFoodGroup.includes(item === null || item === void 0 ? void 0 : item.foodGroup) ? _vm._l(item === null || item === void 0 ? void 0 : item.basicFoodIngredient, function (sub) {
      return _c('c-flex', {
        key: sub,
        attrs: {
          "width": "100%",
          "align-items": "center",
          "justify-content": "space-between",
          "border-top-style": "solid",
          "border-top-width": "1px",
          "border-top-color": "#E2E8F0",
          "p": "10px 20px 10px 30px"
        }
      }, [_c('c-text', {
        attrs: {
          "flex-grow": "1",
          "color": "primary.400",
          "font-size": ['14px', '16px'],
          "font-weight": ['500', '600']
        }
      }, [_vm._v(" " + _vm._s(sub) + " ")]), _c('c-box', {
        attrs: {
          "flex-shrink": "0",
          "cursor": "pointer"
        },
        on: {
          "click": function click($event) {
            return _vm.onSelectFilter(sub);
          }
        }
      }, [_c('c-checkbox', {
        attrs: {
          "size": "lg",
          "variant-color": "primary",
          "pointer-events": 'none',
          "is-checked": _vm.selected_.includes(sub)
        }
      })], 1)], 1);
    }) : _vm._e()], 2)], 1);
  }), 1) : _vm._e(), _c('c-flex', {
    attrs: {
      "p": "8px",
      "gap": "8px"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "outline",
      "variant-color": "primary",
      "width": "100%",
      "height": ['35px', '47px'],
      "border-radius": "58px",
      "font-size": ['14px', '16px']
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onCancel.apply(null, arguments);
      }
    }
  }, [_vm._v(" Batal ")]), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "width": "100%",
      "height": ['35px', '47px'],
      "border-radius": "58px",
      "font-size": ['14px', '16px']
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_vm._v(" Terapkan ")])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }