<template>
  <c-box
    pos="relative"
  >
    <!-- <c-box
      v-if="!loaded"
      class="loader"
    /> -->
    <c-box
      v-if="!loaded && !error"
      pos="absolute"
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
    >
      <c-spinner
        thickness="4px"
        speed="0.65s"
        empty-color="green.200"
        color="vue.500"
        size="lg"
      />
    </c-box>
    <c-image
      v-if="!error"
      v-bind="$attrs"
      v-on="$listeners"
      @load="onLoaded"
      @error="onError"
    />
    <c-flex
      v-else
      flex-direction="column"
      justify-content="center"
      align-items="center"
      background-color="#e5e5e5"
      color="#737373"
      text-align="center"
      :font-size="['10px', '12px']"
      font-weight="500"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="32" 
        height="32" 
        viewBox="0 0 32 32"
      >
        <path 
          fill="currentColor" 
          d="M30 3.414L28.586 2L2 28.586L3.414 30l2-2H26a2.003 2.003 0 0 0 2-2V5.414zM26 26H7.414l7.793-7.793l2.379 2.379a2 2 0 0 0 2.828 0L22 19l4 3.997zm0-5.832l-2.586-2.586a2 2 0 0 0-2.828 0L19 19.168l-2.377-2.377L26 7.414zM6 22v-3l5-4.997l1.373 1.374l1.416-1.416l-1.375-1.375a2 2 0 0 0-2.828 0L6 16.172V6h16V4H6a2.002 2.002 0 0 0-2 2v16z"
        />
      </svg>
      <c-text mt="4px">
        NO IMAGE
      </c-text>
    </c-flex>
  </c-box>
</template>

<script >
import { ref } from '@vue/composition-api';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  let loaded = ref(false);
  let error = ref(false);

  function onLoaded() {
    loaded.value = true;
  }

  function onError() {
    error.value = true;
  }

  return {
    loaded,
    error,
    onLoaded,
    onError
  };
};

export default __sfc_main;
</script>

<style scoped>
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 100%;
  border-top: 5px solid #008c81;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
  /* position: absolute;
  left: calc(50% - 35px);
  top: calc(50% - 35px); */
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
