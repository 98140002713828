/** @typedef {Object} TReturn
 * @property {Boolean} status
 * @property {"OK"} message
*/

/**
  * function get
  * @param {any} axios
  * @returns {Promise<TReturn & {
*   data: {
  *     filter: Array<{
  *       foodGroup: String,
  *       basicFoodIngredient: Array<String>
  *     }>
  *     result: {
  *        id: String,           
  *        foodName: String,
  *        foodGroup: String,
  *        foodIngredient: String,
  *        basicFoodIngredient: String,
  *        servingSize: String | null,
  *        servingSizeUnit: String | null,
  *        householdMeasurement: String | null,
  *        householdMeasurementUnit: String | null,
  *        photoUrl: String,
  *        fatSub: String | null,
  *        carbohydrate: Number | null,
  *        carbohydrateSub: String | null,
  *        natrium: String | null,
  *        iron: String | null,
  *        additonalNutritionContent: String | null,
  *        mainNutritionContent: String | null,
  *        cuisine: String | null,
  *        tag: String | null,
  *        createdAt: String,
  *        updatedAt: String,
  *     }
  *   }
  *   meta: {
  *     total: Number,
  *     page: Number,
  *     pagesize: Number,
  *   }
  * }>}
 */
const get = async(axios, { page, perpage, q, char, filter }) => {
  return axios.get('/v1/meal-glossaries', {
    params: {
      page: page || 1,
      perpage: perpage || 10,
      q: q || null,
      char: char || null,
      filter: filter || null,
    },
  })
}

export const reqMealGlossaries = {
  get,
}