var render = function () {
  var _vm$perPage;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "justify-content": "space-between",
      "align-items": "center",
      "gap": "8px",
      "padding-inline": "8px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "pos": "relative",
      "flex-grow": "1"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg'),
      "alt": "icon",
      "position": "absolute",
      "top": "50%",
      "transform": "translateY(-50%)",
      "left": "15px",
      "height": "24px",
      "z-index": "2"
    }
  }), _c('c-input', {
    attrs: {
      "id": "search",
      "type": "text",
      "w": "100%",
      "height": "48px",
      "placeholder": "Pencarian",
      "border-radius": "20px",
      "padding-left": "50px",
      "position": "relative",
      "font-size": ['14px', '16px'],
      "z-index": "1"
    },
    on: {
      "keyup": _vm.onChangeSearch
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('GlossaryFilter', {
    attrs: {
      "selected": _vm.selectedFilter,
      "filters": _vm.filter
    },
    on: {
      "update:selected": function updateSelected(value) {
        return _vm.onChangeFilter(value);
      }
    }
  }), _vm.isAdmin ? [!_vm.onSelectItem ? _c('c-flex', {
    attrs: {
      "align-items": "center",
      "flex-shrink": "0"
    }
  }, [_c('c-button', {
    on: {
      "click": _vm.onClickChooseItem
    }
  }, [_vm._v(" Pilih ")])], 1) : _c('c-flex', {
    attrs: {
      "gap": "4px",
      "align-items": "center",
      "flex-shrink": "0"
    }
  }, [_c('c-button', {
    attrs: {
      "variant-color": "primary",
      "variant": "solid",
      "is-loading": _vm.isLoadingChooseAll
    },
    on: {
      "click": _vm.onClickChooseAll
    }
  }, [_vm._v(" Pilih Semua ")]), _c('c-button', {
    attrs: {
      "variant-color": "primary",
      "variant": "ghost",
      "is-disabled": _vm.isLoadingChooseAll
    },
    on: {
      "click": _vm.onClickCancelItem
    }
  }, [_vm._v(" Batal ")]), _c('c-button', {
    attrs: {
      "variant-color": "danger",
      "variant": "ghost",
      "is-disabled": _vm.isLoadingChooseAll || _vm.selectedItems.length === 0
    },
    on: {
      "click": _vm.onClickDeleteItem
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-trash.svg'),
      "height": "21",
      "width": "21",
      "fill": "#D32737"
    }
  })], 1)], 1)] : _vm._e()], 2), _c('c-box', {
    attrs: {
      "as": "hr",
      "w": "100%",
      "margin-block": ['12px', '16px'],
      "border-top": "1px solid #E2E8F0"
    }
  }), _c('c-flex', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedFilter && _vm.selectedFilter.length > 0,
      expression: "selectedFilter && selectedFilter.length > 0"
    }],
    ref: "filterContainer",
    attrs: {
      "position": "relative",
      "width": "100%",
      "margin-bottom": "8px",
      "align-items": "center"
    }
  }, [_c('c-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isOverflowFilter,
      expression: "isOverflowFilter"
    }],
    attrs: {
      "display": ['none', 'block'],
      "width": "30px",
      "min-width": "30px",
      "height": "30px",
      "text-align": "center",
      "margin": "0",
      "padding": "0",
      "border-radius": "100%",
      "position": "absolute",
      "left": "-35px",
      "is-disabled": false
    },
    on: {
      "click": function click($event) {
        return _vm.onScrollContent('filterContent', 'left', 15, 100, 5);
      }
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['12px', '15px'],
      "height": ['12px', '15px']
    }
  }, [_c('inline-svg', {
    staticStyle: {
      "transform": "rotate(90deg)"
    },
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('c-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isOverflowFilter,
      expression: "isOverflowFilter"
    }],
    attrs: {
      "display": ['none', 'block'],
      "width": "30px",
      "min-width": "30px",
      "height": "30px",
      "text-align": "center",
      "margin": "0",
      "padding": "0",
      "border-radius": "100%",
      "position": "absolute",
      "right": "-35px",
      "is-disabled": false
    },
    on: {
      "click": function click($event) {
        return _vm.onScrollContent('filterContent', 'right', 15, 100, 5);
      }
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['12px', '15px'],
      "height": ['12px', '15px']
    }
  }, [_c('inline-svg', {
    staticStyle: {
      "transform": "rotate(-90deg)"
    },
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('c-flex', {
    directives: [{
      name: "dragscroll",
      rawName: "v-dragscroll"
    }],
    ref: "filterContent",
    attrs: {
      "padding-inline": "8px",
      "padding-block": "4px",
      "justify-content": "flex-start",
      "gap": ['6px', '12px'],
      "h": "auto",
      "overflow-x": "hidden",
      "scroll-behavior": "smooth"
    }
  }, _vm._l(_vm.selectedFilter, function (item, i) {
    return _c('c-button', {
      key: i,
      attrs: {
        "flex-shrink": "0",
        "variant-color": "primary",
        "variant": "outline",
        "border-color": "transparent",
        "bg": "primary.50",
        "rounded": "20px",
        "h": ['34px', '40px'],
        "font-size": ['14px', '16px']
      },
      on: {
        "click": function click($event) {
          return _vm.onRemoveFilterItem(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item) + " "), _c('c-box', {
      attrs: {
        "margin-left": "6px",
        "width": ['18px', '20px'],
        "height": ['18px', '20px']
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-circle-close.svg'),
        "height": "100%",
        "width": "100%",
        "fill": "#008C81"
      }
    })], 1)], 1);
  }), 1)], 1), _c('c-flex', {
    attrs: {
      "position": "relative",
      "width": "100%",
      "align-items": "center",
      "padding-inline": ['34px', '0px']
    }
  }, [_c('c-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isOverflowChar,
      expression: "isOverflowChar"
    }],
    attrs: {
      "width": ['26px', '30px'],
      "min-width": ['26px', '30px'],
      "height": ['26px', '30px'],
      "text-align": "center",
      "margin": "0",
      "padding": "0",
      "border-radius": "100%",
      "position": "absolute",
      "left": ['6px', '-35px'],
      "is-disabled": false
    },
    on: {
      "click": function click($event) {
        return _vm.onScrollContent('charContent', 'left', 15, 100, 5);
      }
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['12px', '15px'],
      "height": ['12px', '15px']
    }
  }, [_c('inline-svg', {
    staticStyle: {
      "transform": "rotate(90deg)"
    },
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('c-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isOverflowChar,
      expression: "isOverflowChar"
    }],
    attrs: {
      "width": ['26px', '30px'],
      "min-width": ['26px', '30px'],
      "height": ['26px', '30px'],
      "text-align": "center",
      "margin": "0",
      "padding": "0",
      "border-radius": "100%",
      "position": "absolute",
      "right": ['6px', '-35px'],
      "is-disabled": false
    },
    on: {
      "click": function click($event) {
        return _vm.onScrollContent('charContent', 'right', 15, 100, 5);
      }
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['12px', '15px'],
      "height": ['12px', '15px']
    }
  }, [_c('inline-svg', {
    staticStyle: {
      "transform": "rotate(-90deg)"
    },
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('c-flex', {
    directives: [{
      name: "dragscroll",
      rawName: "v-dragscroll"
    }],
    ref: "charContent",
    attrs: {
      "justify-content": "flex-start",
      "grid-gap": ['4px', '8px'],
      "h": "auto",
      "padding-inline": ['4px', '8px'],
      "padding-block": "4px",
      "overflow-x": "hidden"
    }
  }, [_c('c-button', {
    attrs: {
      "type": "button",
      "h": ['26px', '32px'],
      "w": "auto",
      "flex-shrink": "0",
      "px": "6px",
      "m": "0",
      "font-size": ['14px', '16px'],
      "line-height": "24px",
      "rounded": "full",
      "text-transform": "uppercase",
      "color": !_vm.char ? 'primary.400' : 'gray.900',
      "bg": !_vm.char ? 'primary.50' : 'white',
      "border-width": "1px",
      "border-style": "solid",
      "border-color": !_vm.char ? 'primary.400' : 'gray.900'
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.onChangeChar('');
      }
    }
  }, [_vm._v(" Semua ")]), _vm._l(_vm.chars, function (item) {
    return _c('c-button', {
      key: item,
      attrs: {
        "type": "button",
        "h": ['26px', '32px'],
        "w": ['26px', '32px'],
        "min-w": ['26px', '32px'],
        "px": "0",
        "m": "0",
        "font-size": ['14px', '16px'],
        "line-height": "24px",
        "rounded": "full",
        "text-transform": "uppercase",
        "color": _vm.char === item ? 'primary.400' : 'gray.900',
        "bg": _vm.char === item ? 'primary.50' : 'white',
        "border-width": "1px",
        "border-style": "solid",
        "border-color": _vm.char === item ? 'primary.400' : 'gray.900'
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          $event.preventDefault();
          return _vm.onChangeChar(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  })], 2)], 1), _vm.isLoading || _vm.items.length > 0 ? _c('c-box', {
    attrs: {
      "min-height": ['calc(100vh - 210px)', 'auto']
    }
  }, [_c('c-grid', {
    attrs: {
      "w": "100%",
      "template-columns": ['repeat(3, 1fr)', 'repeat(5, 1fr)'],
      "gap": ['8px', '16px'],
      "mx": "auto",
      "my": "16px"
    }
  }, [_vm.isLoading ? _vm._l(Number(_vm.perPage), function (i) {
    return _c('GlossaryItemSkeleton', {
      key: i
    });
  }) : _vm._l(_vm.items, function (item, i) {
    return _c('GlossaryItem', {
      key: i,
      attrs: {
        "data": item,
        "on-select": _vm.onSelectItem,
        "is-selected": _vm.isItemChecked(item === null || item === void 0 ? void 0 : item.id)
      },
      on: {
        "click": _vm.onClickGlossary
      }
    });
  })], 2)], 1) : _c('c-flex', {
    attrs: {
      "width": "100%",
      "min-height": ['calc(100vh - 200px)', 'auto'],
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      "padding-block": "30px"
    }
  }, [_c('c-image', {
    attrs: {
      "src": "https://ik.imagekit.io/dietela/pwa_webp/profile/undraw_empty_re_opql_2.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1677059485965",
      "h": ['170px', '300px'],
      "mb": "20px"
    }
  }), _c('c-text', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": "superDarkGray.900"
    }
  }, [_vm._v(" Saat ini belum ada nilai gizi ")])], 1), _c('c-flex', {
    attrs: {
      "justify": "space-between",
      "align": "center",
      "padding": "8px 16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "align": "center",
      "gap": "16px"
    }
  }, [_c('BaseText', {
    attrs: {
      "display": ['none', 'block'],
      "size-mobile": "14px-2",
      "size-desktop": "16px"
    }
  }, [_vm._v(" Show ")]), _c('c-select', {
    attrs: {
      "min-width": "140px",
      "border-radius": "8px",
      "size": "md",
      "color": "primary.400",
      "font-size": ['16px', '18px'],
      "font-weight": "500",
      "value": (_vm$perPage = _vm.perPage) === null || _vm$perPage === void 0 ? void 0 : _vm$perPage.toString()
    },
    on: {
      "change": function change(perPage_) {
        return _vm.onChangePerPage(perPage_);
      }
    }
  }, _vm._l(_vm.perPageOptions, function (option) {
    return _c('option', {
      key: option,
      domProps: {
        "value": option
      }
    }, [_vm._v(" " + _vm._s(option) + " Data ")]);
  }), 0)], 1), _c('c-flex', {
    attrs: {
      "display": ['none', 'flex'],
      "font-size": "14px",
      "font-weight": "400",
      "font-family": "Roboto, sans-serif, Arial",
      "padding": "12px 0",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('BasePagination', {
    attrs: {
      "page": _vm.page,
      "total-page": _vm.totalPage
    },
    on: {
      "on-change": _vm.onChangePage
    }
  })], 1), _c('c-flex', {
    attrs: {
      "display": ['flex', 'none'],
      "gap": "12px",
      "align-items": "center"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "outline",
      "variant-color": "primary",
      "min-width": "40px",
      "width": "40px",
      "height": "40px",
      "border-radius": "40px",
      "padding": "0px",
      "is-disabled": _vm.page <= 1
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onPrevPage.apply(null, arguments);
      }
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['15px']
    }
  }, [_c('inline-svg', {
    staticStyle: {
      "transform": "rotate(90deg)"
    },
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('c-text', {
    attrs: {
      "font-weight": "500",
      "font-size": "16px"
    }
  }, [_vm._v(" " + _vm._s(_vm.page) + " ")]), _c('c-button', {
    attrs: {
      "variant": "outline",
      "variant-color": "primary",
      "min-width": "40px",
      "width": "40px",
      "height": "40px",
      "border-radius": "40px",
      "padding": "0px",
      "is-disabled": _vm.page == _vm.totalPage || _vm.items.length == 0
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onNextPage.apply(null, arguments);
      }
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['15px']
    }
  }, [_c('inline-svg', {
    staticStyle: {
      "transform": "rotate(-90deg)"
    },
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1)], 1)], 1), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenConfirmDelete,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": "https://ik.imagekit.io/dietela/pwa_webp/popup/delete.png?updatedAt=1690890254641",
            "alt": "image confirmation delete coupon"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 24px 20px 24px",
            "text-align": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px",
            "color": "#333333"
          }
        }, [_vm._v(" Apakah kamu yakin ingin menghapus item terpilih? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined",
            "is-disabled": _vm.isLoadingDelete
          },
          on: {
            "click": function click($event) {
              _vm.isOpenConfirmDelete = false;
            }
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "danger",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isLoadingDelete
          },
          on: {
            "click": _vm.onSubmitDeleteItem
          }
        }, [_vm._v(" Hapus ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }