var render = function () {
  var _vm$data, _vm$data2, _vm$data3, _vm$data4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          background: '#F2F2F2'
        }
      },
      expression: "{\n    ':hover': {\n      background: '#F2F2F2',\n    },\n  }"
    }],
    attrs: {
      "position": "relative",
      "width": "100%",
      "flex-direction": "column",
      "align-items": "center",
      "padding": ['4px', '8px'],
      "cursor": "pointer"
    },
    on: {
      "click": _vm.onClickItem
    }
  }, [_c('c-box', {
    attrs: {
      "position": "relative"
    }
  }, [_c('SpinningImg', {
    attrs: {
      "src": (_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : _vm$data.photoUrl,
      "position": "relative",
      "width": ['80px', '100px'],
      "height": ['80px', '100px'],
      "flex-shrink": "0",
      "border-radius": "6px",
      "object-fit": "cover",
      "margin-bottom": "4px"
    }
  }), _vm.onSelect ? _c('c-checkbox', {
    attrs: {
      "size": "lg",
      "variant-color": "primary",
      "position": "absolute",
      "top": "-4px",
      "left": "-4px",
      "background-color": "white",
      "is-checked": _vm.isSelected,
      "pointer-events": 'none'
    }
  }) : _vm._e()], 1), _c('c-text', {
    staticClass: "line-clamp-2",
    attrs: {
      "font-size": ['12px', '14px'],
      "font-weight": "400",
      "color": "black.900",
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : _vm$data2.foodName) + " ")]), _c('c-text', {
    attrs: {
      "font-size": ['12px', '14px'],
      "font-weight": "400",
      "color": "gray.900",
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s((_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : _vm$data3.servingSize) + " " + _vm._s((_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : _vm$data4.servingSizeUnit) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }