<template>
  <c-flex
    v-chakra="{
      ':hover': {
        background: '#F2F2F2',
      },
    }"
    position="relative"
    width="100%"
    flex-direction="column"
    align-items="center"
    :padding="['4px', '8px']"
    cursor="pointer"
    @click="onClickGlossary(i)"
  >
    <c-box position="relative">
      <c-box
        :width="['80px', '100px']"
        :height="['80px', '100px']"
        mb="4px"
        border-radius="6px"
        margin-bottom="4px"
      >
        <vue-skeleton-loader
          type="rect"
          width="100%"
          height="100%"
          animation="fade"
          rounded
        />
      </c-box>
    </c-box>
    <c-box
      :w="['90px', '140px']"
      :h="['18px', '20px']"
      mb="4px"
    >
      <vue-skeleton-loader
        type="rect"
        width="100%"
        height="100%"
        animation="fade"
        rounded
      />
    </c-box>
    <c-box
      :w="['50px', '60px']"
      :h="['18px', '20px']"
    >
      <vue-skeleton-loader
        type="rect"
        width="100%"
        height="100%"
        animation="fade"
        rounded
      />
    </c-box>
  </c-flex>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  name: 'GlossaryItemSkeleton',
  components: {
    VueSkeletonLoader,
  },
}
</script>

<style></style>
