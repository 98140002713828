<template>
  <c-flex
    v-chakra="{
      ':hover': {
        background: '#F2F2F2',
      },
    }"
    position="relative"
    width="100%"
    flex-direction="column"
    align-items="center"
    :padding="['4px', '8px']"
    cursor="pointer"
    @click="onClickItem"
  >
    <c-box position="relative">
      <SpinningImg 
        :src="data?.photoUrl"
        position="relative"
        :width="['80px', '100px']"
        :height="['80px', '100px']"
        flex-shrink="0"
        border-radius="6px"
        object-fit="cover"
        margin-bottom="4px"
      />
      <c-checkbox
        v-if="onSelect"
        size="lg"
        variant-color="primary"
        position="absolute"
        top="-4px"
        left="-4px"
        background-color="white"
        :is-checked="isSelected"
        :pointer-events="'none'"
      />
    </c-box>
    <c-text 
      :font-size="['12px', '14px']" 
      font-weight="400" 
      color="black.900" 
      text-align="center"
      class="line-clamp-2"
    >
      {{ data?.foodName }}
    </c-text>
    <c-text 
      :font-size="['12px', '14px']" 
      font-weight="400" 
      color="gray.900" 
      text-align="center"
    > 
      {{ data?.servingSize }} {{ data?.servingSizeUnit }}
    </c-text>
  </c-flex>
</template>

<script>
import SpinningImg from '@/components/spinning-img.vue'

export default {
  name: 'GlossaryItem',
  components: {
    SpinningImg,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    onSelect: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'click',
  ],
  methods: {
    onClickItem() {
      this.$emit('click', this.data)
    },
  },
}
</script>

<style></style>
