<template>
  <c-box
    position="relative"
  >
    <c-button
      variant="ghost"
      p="0"
      position="relative"
      @click.prevent="onOpenMenu"
    >
      <c-box
        width="30px"
        height="30px"
        color="#008C81"
      >
        <inline-svg
          :src="require('@/assets/icons/icon-tabler-filter.svg')"
          height="100%"
          width="100%"
        />
      </c-box>
      <c-flex
        v-if="selected && selected.length > 0"
        pos="absolute"
        top="0"
        right="0"
        width="16px"
        height="16px"
        align-items="center"
        justify-content="center"
        border-radius="100%"
        font-size="10px"
        background="#ef4444"
        color="#FFF"
      >
        {{ selected.length }}
      </c-flex>
    </c-button>
    <c-box
      v-if="isOpen"
      position="absolute"
      top="0"
      right="40px"
      :min-width="['280px', '450px']"
      :max-width="['300px', '500px']"
      flex-direction="column"
      padding="0"
      background-color="#FFF"
      border="1px solid #E2E8F0"
      border-radius="6px"
      z-index="10"
    >
      <c-flex
        p="10px 10px 10px 20px"
        justify-content="space-between"
        align-items="center"
      >
        <c-text
          color="primary.400"
          :font-size="['14px', '16px']"
          :font-weight="['500', '600']"
        >
          Filter
        </c-text>
        <c-button
          variant="ghost"
          variant-color="danger"
          :font-size="['14px', '16px']"
          @click="onRemoveAllFilter"
        >
          Hapus Semua
        </c-button>
      </c-flex>
      <c-flex
        v-if="filters.length > 0"
        flex-direction="column"
        align-items="center"
        max-height="350px"
        overflow-y="auto"
      >
        <c-flex
          v-for="item in filters"
          :key="item?.foodGroup"
          width="100%"
          flex-direction="column"
        >
          <c-flex
            width="100%"
            align-items="center"
            justify-content="space-between"
            border-top-style="solid"
            border-top-width="1px"
            border-top-color="#E2E8F0"
            p="10px 10px 10px 20px"
          >
            <c-text
              flex-grow="1"
              color="primary.400"
              :font-size="['14px', '16px']"
              :font-weight="['500', '600']"
            >
              {{ item?.foodGroup }}
            </c-text>
            <c-flex
              flex-shrink="0"
              align-items="center"
              gap="8px"
            >
              <c-box
                cursor="pointer"
                @click="onSelectFilter(item?.foodGroup)"
              >
                <c-checkbox
                  size="lg"
                  variant-color="primary"
                  :pointer-events="'none'"
                  :is-checked="selected_.includes(item?.foodGroup)"
                />
              </c-box>
              <c-button
                v-if="item?.basicFoodIngredient?.length > 0"
                variant="ghost"
                size="sm"
                padding-inline="6px"
                @click="onToggleFoodGroup(item?.foodGroup)"
              >
                <c-box
                  :transform="openFoodGroup.includes(item?.foodGroup) ? ['rotate(180deg)'] : ['rotate(0deg)']"
                  :width="['12px', '16px']"
                  :height="['12px', '16px']"
                >
                  <inline-svg
                    :src="require('@/assets/icons/icon-arrow-down.svg')"
                    fill="#888"
                    width="100%"
                    height="100%"
                  />
                </c-box>
              </c-button>
            </c-flex>
          </c-flex>
          <transition-group
            name="slide-down"
          >
            <template
              v-if="item?.basicFoodIngredient?.length > 0 && openFoodGroup.includes(item?.foodGroup)"
            >
              <c-flex
                v-for="sub in item?.basicFoodIngredient"
                :key="sub"
                width="100%"
                align-items="center"
                justify-content="space-between"
                border-top-style="solid"
                border-top-width="1px"
                border-top-color="#E2E8F0"
                p="10px 20px 10px 30px"
              >
                <c-text 
                  flex-grow="1"
                  color="primary.400"
                  :font-size="['14px', '16px']"
                  :font-weight="['500', '600']"
                >
                  {{ sub }}
                </c-text>
                <c-box
                  flex-shrink="0"
                  cursor="pointer"
                  @click="onSelectFilter(sub)"
                >
                  <c-checkbox
                    size="lg"
                    variant-color="primary"
                    :pointer-events="'none'"
                    :is-checked="selected_.includes(sub)"
                  />
                </c-box>
              </c-flex>
            </template>
          </transition-group>
        </c-flex>
      </c-flex>
      <c-flex
        p="8px"
        gap="8px"
      >
        <c-button
          variant="outline"
          variant-color="primary"
          width="100%"
          :height="['35px', '47px']"
          border-radius="58px"
          :font-size="['14px', '16px']"
          @click.prevent="onCancel"
        >
          Batal
        </c-button>
        <c-button
          variant="solid"
          variant-color="primary"
          width="100%"
          :height="['35px', '47px']"
          border-radius="58px"
          :font-size="['14px', '16px']"
          @click.prevent="onSubmit"
        >
          Terapkan
        </c-button>
      </c-flex>
    </c-box>
  </c-box>
</template>

<script>
export default {
  name: 'GlossaryFilter',
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:selected'],
  data() {
    return {
      isOpen: false,
      selected_: [],
      openFoodGroup: [],
    }
  },
  watch: {
    selected: {
      immediate: true,
      handler(val) {
        this.selected_ = val
      },
    },
  },
  methods: {
    onOpenMenu() {
      this.isOpen = true
    },
    onCloseMenu() {
      this.isOpen = false
    },
    onToggleFoodGroup(value) {
      if (this.openFoodGroup.some((it) => it == value)) {
        this.openFoodGroup = this.openFoodGroup.filter((it) => it != value)
      } else {
        this.openFoodGroup = [...this.openFoodGroup, value]
      }
    },
    onRemoveAllFilter() {
      this.selected_ = []
    },
    onSelectFilter(filter) {
      if (this.selected_.some((it) => it == filter)) {
        this.selected_ = this.selected_.filter((it) => it != filter)
      } else {
        this.selected_ = [...this.selected_, filter]
      }
    },
    onSubmit() {
      this.$emit('update:selected', this.selected_)
      this.onCloseMenu()
    },
    onCancel() {
      this.onCloseMenu()
      this.selected_ = this.selected
    },
  },
}
</script>

<style>

</style>